<template>
  <div class="wrapper flex justify-content-center mx-auto">
    <button class="m-0" type="submit"
            @click="logout()">
        {{ $t('logout') }}
    </button>
  </div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
const cookies = require('js-cookie')

const AccountAction = namespace('account').Action
const AccountMutation = namespace('account').Mutation

const AppProps = Vue.extend({
  props: {
    modalName: String,
    censoredEmail: String,
  },
  data() {
    return {
      checked: false,
    }
  },
  methods: {
    closeModal() {
      this.$emit('close-modal')
    },

    resendEmailUpdate() {
      this.$emit('resend-email-update');
    },

    async logout() {
      const authToken = cookies.get('token');

      await this.logoutUser(authToken);

      cookies.remove('magicToken')
      cookies.remove('token')
      cookies.remove('banner_id')
      cookies.remove('play_real_dialog_shown')
      cookies.remove('userId')
      cookies.remove('isMagicTokenLogin')
      cookies.remove('firstLoginPending')
      sessionStorage.removeItem('session-id')

      this.setIsLoggedIn(false)
      this.setHasMagicToken(false)
      this.setMagicToken('')
      this.setToken('')
      this.setUserId('')

      const query = Object.assign({}, this.$route.query);
      delete query.account_key;
      window.location.href = '/';
    },
  },
})

@Component({
  components: {}
})

export default class Logout extends AppProps {
  @AccountAction logoutUser

  @AccountMutation setIsLoggedIn
  @AccountMutation setMagicToken
  @AccountMutation setHasMagicToken
  @AccountMutation setToken
  @AccountMutation setUserId
}
</script>

<style scoped>
* {
  padding: 0 1rem;
  font-family: 'Gilroy-Regular', sans-serif;
}

h1 {
  margin: 2rem 0;
  font-weight: 700;
}

h3,
h6 {
  font-weight: 700;
  margin: 4rem 0 2rem 0;
}

img {
  max-width: 100%;
  margin-top: 12px;
}

button {
  border: none;
  color: white;
  font-weight: bold;
  border-radius: 4px;
  padding: 10px 20px;
  margin: 1rem 0 0 1rem;
  background: #0ac18e;
}

.wrapper {
  width: 700px;
  padding: 75px 0 75px 100px;
}

@media only screen and (max-width: 900px) {
  .wrapper {
    padding: 0;
    width: 100%;
  }
}
</style>
